// import axios from 'axios';
// import React, { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import Footer from '../components/Footer/Footer';

// export default function Reviews() {
//     const [reviews, setReviews] = useState([]);
//     const fetchData = async () => {
//         const response = await axios.get('https://localhost:3001/api/reviews');
//         setReviews(response.data);
//       };
    
//       useEffect(() => {
//         fetchData();
//       }, []);
//      return (
     
//     <div>
//         <Footer/>
//         </div>
        
//     )
// }

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer/Footer';
import ReviewCard from '../components/ReviewCard/ReviewCard'; // Adjust the import path accordingly
import { Container, Grid } from '@mui/material';

export default function Reviews() {
  console.log("test");
  const [reviews, setReviews] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get('http://localhost:3001/api/reviews');
      console.log(response);
      const displayedReviews = response.data.filter(review => review.displayed === 1);
      console.log(displayedReviews);
      setReviews(displayedReviews);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Container>
        <Grid container spacing={2}>
          {reviews.map((review) => (
            <Grid item xs={12} sm={6} md={4} key={review.id}>
              <ReviewCard review={review} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </div>
  );
}

