import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import "aos/dist/aos.css";
import Service from "./pages/service";
import Services from "./pages/Services";
import Story from "./pages/ourStory";
import Contact from "./pages/Contact";
import Review from "./pages/Review";
import Reviews from "./pages/Reviews";
import React, { useState } from "react";
import TermsAndConditions from "./pages/TermsAndConditions";

function App() {
  // console.log(QuoteService);
  const [state, setState] = useState(0);
  const QuoteService = () => {
    setState(1);
  };

  return (
    <Router>
      <Navbar QuoteService={QuoteService} />
      <Routes>
        <Route path="/" element={<Home state={state} />} />
        <Route path="/service" element={<Service />} />
        <Route path="/ourStory" element={<Story />} />
        <Route path="/service/:name" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/review/:id" element={<Review />} />
        <Route path="/reviews" element={<Reviews />} />\{" "}
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
      </Routes>
      {/* <Footer/> */}
    </Router>
  );
}

export default App;
