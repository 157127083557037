import React, { useEffect, useState, useRef } from "react";
import AOS from "aos";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Quote from "../components/Quote/Quote";
import Interpretation from "../components/Interpretation/interpretation";
import Footer from "../components/Footer/Footer";
import servicesData from "../lib/data/servicesData";

export default function Home({ state }) {
  const services = servicesData || [];
  const location = useLocation();

  const renderServiceRef = useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [backgroundImage, setBackgroundImage] = useState(
    "/assets/images/BGPhotoFinal.jpg"
  );
  const [activeService, setActiveService] = useState(null);
  const [gap, setGap] = useState(window.innerWidth <= 768 ? "1px" : "200px");
  const [buttonStyle, setButtonStyle] = useState({
    width: window.innerWidth <= 768 ? "150px" : "300px",
    height: window.innerWidth <= 768 ? "60px" : "65px",
    fontSize: window.innerWidth <= 768 ? "12px" : "16px",
    padding: window.innerWidth <= 768 ? "15px" : "1px",
    imgwidth: window.innerWidth <= 768 ? "180px " : "300px",
    imgheight: window.innerWidth <= 768 ? "170px " : "250px",
    fontSizefalcon: window.innerWidth <= 768 ? "2.0rem" : "2.5rem",
    fontSizetranslation: window.innerWidth <= 768 ? "1.1rem" : "1.4rem",
    font: window.innerWidth <= 768 ? "0.8rem" : "",
    marginBottom: window.innerWidth <= 768 ? "20px" : "30px",
    marginTop: window.innerWidth <= 768 ? "10px" : "",
    hrwidth: window.innerWidth <= 768 ? "64%" : "35%",
  });
  const [heroHeight, setHeroHeight] = useState("");

  const QuoteService = () => {
    setActiveService("document-translation");

    // Calculate the target scroll position with an offset
    const offset = -70; // Adjust this value as needed for a 50px offset
    const elementPosition =
      renderServiceRef.current.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition + offset;

    // Smoothly scroll to the calculated position
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const handleResizee = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    if (location.state?.triggerQuoteService) {
      // Add a delay to ensure the component is fully rendered
      setTimeout(() => {
        QuoteService();
      }, 100); // 100ms delay, adjust if necessary

      // Clear the trigger to avoid repeated scrolling on refresh
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  useEffect(() => {
    if (state) {
      QuoteService();
    }
  }, [state]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
    window.addEventListener("resize", handleResizee);
    ///
    const updateBackgroundImage = () => {
      if (window.innerWidth <= 768) {
        setBackgroundImage("/assets/images/MobileBG.jpg");
      } else {
        setBackgroundImage("/assets/images/BGPhotoFinal.jpg");
      }
    };
    updateBackgroundImage(); // Initial check
    window.addEventListener("resize", updateBackgroundImage);
    ////
    const handleResize = () => {
      setGap(window.innerWidth <= 768 ? "1px" : "200px");
      setButtonStyle({
        width: window.innerWidth <= 768 ? "150px" : "300px",
        height: window.innerWidth <= 768 ? "60px" : "65px",
        fontSize: window.innerWidth <= 768 ? "12px" : "16px",
        padding: window.innerWidth <= 768 ? "15px" : "1px",
        imgwidth: window.innerWidth <= 768 ? "180px " : "300px",
        imgheight: window.innerWidth <= 768 ? "170px " : "250px",
        fontSizefalcon: window.innerWidth <= 768 ? "1.4rem" : "2.5rem",
        fontSizetranslation: window.innerWidth <= 768 ? "0.8rem" : "1.4rem",
        font: window.innerWidth <= 768 ? "0.8rem" : "",
        marginBottom: window.innerWidth <= 768 ? "20px" : "60px",
        marginTop: window.innerWidth <= 768 ? "10px" : "",
        hrwidth: window.innerWidth <= 768 ? "64%" : "35%",
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleResizee);
      window.removeEventListener("resize", updateBackgroundImage);
    };
  }, []);

  useEffect(() => {
    const updateBackgroundSize = () => {
      const height = window.innerHeight;
      const navbar = document.querySelector(".navbar");
      if (navbar) {
        const navbarHeight = navbar.clientHeight;
        setHeroHeight(`${height - navbarHeight}px`);
      }
    };
    updateBackgroundSize();
    window.addEventListener("resize", updateBackgroundSize);

    return () => {
      window.removeEventListener("resize", updateBackgroundSize);
    };
  }, []);

  const renderService = () => {
    // eslint-disable-next-line default-case
    switch (activeService) {
      case "document-translation":
        return <Quote />;
      case "interpretation":
        return <Interpretation />;
    }
  };

  return (
    <>
      <div
        className="background"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "100% 100vh",
          backgroundPosition: "center",
          height: heroHeight,
          paddingTop: "10px",
        }}
      >
        <div className="container h-100 d-flex align-items-center justify-content-center text-center">
          <div>
            <div style={{ marginBottom: buttonStyle.marginBottom }}>
              <img
                src="/assets/images/falconLogo.png"
                alt="logo"
                className="d-inline-block align-text-top"
                style={{
                  width: buttonStyle.imgwidth,
                  height: buttonStyle.imgheight,
                }}
              />

              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    width: buttonStyle.hrwidth,
                    height: "3px",
                    backgroundColor: "black",
                    marginBottom: "10px",
                  }}
                />
              </div>

              <h6 className="text-black" style={{ fontSize: buttonStyle.font }}>
                Accuracy. Speed. Efficiency
              </h6>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    width: buttonStyle.hrwidth,
                    height: "3px",
                    backgroundColor: "black",
                  }}
                />
              </div>
            </div>
            <div>
              <p
                className={
                  isMobile ? "text-black fs-5 gothic" : "text-black fs-3 gothic"
                }
                style={{
                  fontWeight: "bold",
                }}
              >
                We provide high-quality translation services to clients in the
                UK <br></br> and around the world.
              </p>

              {/* <Link to="/contact" className='btn btn-violet py-2 px-3 fs-5 gothic'>Get Your Quote Now!</Link> */}

              <div
                style={{
                  display: "flex",
                  gap: gap,
                  marginTop: "10px",
                  justifyContent: "center",
                }}
              >
                <div>
                  <div
                    className="services-card"
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                      fontWeight: "bold",
                      cursor: "pointer",
                      boxShadow: "0 0 30px 10px rgba(0, 0, 0, 0.4)",
                      background: `linear-gradient(${
                        activeService === "document-translation"
                          ? "#4a259b"
                          : "white"
                      }, ${
                        activeService === "document-translation"
                          ? "#4a259b"
                          : "white"
                      })`,
                      borderLeft:
                        activeService === "document-translation"
                          ? "10px solid #e5e0f1"
                          : "10px solid #4a259b",
                    }}
                    onClick={QuoteService}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <h6
                        style={{
                          color:
                            activeService === "document-translation"
                              ? "white"
                              : "#4a259b",
                          fontSize: buttonStyle.fontSize,
                          margin: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        Fast Quote
                      </h6>
                    </div>
                  </div>
                </div>
                {/* <div>
    <div className='services-card' style={{paddingTop:buttonStyle.padding,width:buttonStyle.width,height:buttonStyle.height,cursor: 'pointer',boxShadow:"0 0 30px 10px rgba(0, 0, 0, 0.4)",background:`linear-gradient(${activeService === 'interpretation' ? '#4a259b' : 'rgba(255, 250, 250, 0.5)'}, ${activeService === 'interpretation' ? '#4a259b' : 'rgba(255, 250, 250, 0.5)'})`,borderLeft: activeService === 'interpretation' ? '10px solid #e5e0f1' :'10px solid #4a259b' }} onClick={() => {setActiveService('interpretation'); renderServiceRef.current.scrollIntoView({ behavior: 'smooth' });}}>
        <div className={isMobile ? 'text-leftt' : 'text-center'}>
        <h6 style={{color: activeService === 'interpretation' ? 'white' : '#4a259b',fontSize:buttonStyle.fontSize}}>Fast Quote</h6>
            <h6 style={{marginTop:"4px",color:activeService === 'interpretation' ? 'white' : 'black', fontSize:buttonStyle.fontSize}}>Interpretation</h6>
        </div>
    </div>
</div>
                         */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={renderServiceRef}>{renderService()}</div>
      {/* <Quote /> */}
      <br></br>

      <div className="py-5">
        <h2
          className={
            isMobile ? "text-center fontt" : "text-center fontt bottomm-falcon"
          }
        >
          We Are Falcons
        </h2>
        <div className="container col-10">
          <div className={`row ${isMobile ? "gap-4" : ""}`}>
            <div className="col-md-6 d-flex">
              <div className="text-size gothic">
                <p style={{ display: "flex", alignItems: "start" }}>
                  In the realm of global discourse, Falcon Translation stands as
                  a beacon of linguistic mastery of the Translation expertise:
                  efficiency in the accuracy and speed. <br />
                  With the precision of a falcon's strike, we deliver
                  translations that resonate with clarity and intent. Our rapid
                  service is the embodiment of the falcon's swift hunt, ensuring
                  messages are conveyed with prompt elegance.
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Link
                    to={`/ourStory`}
                    className="btn btn-violet gothic py-2 px-3 fs-6"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src="/assets/images/about-us.webp"
                alt="About Us"
                className="img-fluid rounded"
                style={{
                  borderLeft: "10px solid #4a259b",
                  height: "100%",
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="py-5 bg-gray">
        <h1 className="text-center fontt">Our Services</h1>
        <div className="container">
          <div className="row isotopeContainer">
            {services &&
              services.map((service, index) => {
                return (
                  //
                  <div
                    key={service.id}
                    className={`col-md-4 mb-3 d-flex portfolio_grid no-padding isotopeSelector ${
                      index === services.length - 1 ? "centered" : ""
                    }`}
                    style={
                      index === services.length - 1
                        ? { margin: "auto", display: "block" }
                        : {}
                    }
                  >
                    <figure className="portfolio_item">
                      <div className="services-card py-5">
                        <div
                          className="text-center"
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            flexDirection: "column",
                            height: "400px",
                          }}
                        >
                          <div
                            style={{
                              flex: "1",
                            }}
                          >
                            <i className={service.icon}></i>
                            <h6 className="mt-3">{service.title}</h6>
                          </div>
                          <p
                            className="gothic"
                            style={{
                              display: "flex",
                              alignItems: "start",
                              flex: "1",
                            }}
                          >
                            <span
                              className="text text-center"
                              style={{ flex: "1" }}
                            >
                              {service.mini}
                            </span>
                          </p>
                          <div>
                            <Link
                              to={`/service/${service.link}`}
                              state={{ service }}
                              className="btn btn-violet gothic py-2 px-3 fs-6"
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </figure>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          fontFamily: "good",
          textTransform: "uppercase",
          textAlign: "center",
          padding: isMobile ? "30px" : "60px",
        }}
      >
        <p
          style={{
            fontSize: "1rem",
          }}
        >
          Choose Falcon Translation for reliable, meticulous, and effective
          translation services.
        </p>
        <p
          style={{
            fontSize: "1rem",
            color: "#4a259b",
            display: isMobile ? "flex" : "block",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Falcon Translation {isMobile && <br />}
          </span>{" "}
          <span
            style={{
              fontSize: "12px",
            }}
          >
            Accuracy. Speed. Efficiency
          </span>
        </p>
      </div>

      <div className="col-md-4 mb-3"> </div>

      <div className="py-5 bg-gray">
        <h1 className="text-center fontt">Our Qualifications</h1>
        <div className="container">
          <div className="row isotopeContainer">
            <div
              className="col-md-4 mb-3 d-flex portfolio_grid no-padding isotopeSelector centered"
              style={{ margin: "auto", display: "block" }}
            >
              <figure className="portfolio_item">
                <div className="services-card py-5">
                  <div className="text-center">
                    {/* <h1 className='text-center fontt'>Our Qualifications</h1> */}
                    <div
                      className="container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <i className="fa-solid fa-ribbon icon"></i>

                      <img
                        src="/assets/images/certificate.jpg"
                        alt="logo"
                        width="100%"
                        className="d-inline-block align-text-top"
                      />
                      <p
                        className="text-size gothic"
                        style={{ textAlign: "center" }}
                      >
                        We are accredited by Chartered Institute of Linguistics
                        CIOL.
                      </p>
                    </div>
                  </div>
                </div>
              </figure>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-3"> </div>

      <Footer service={QuoteService} />
    </>
  );
}
